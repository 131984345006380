import React from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'

class UpdatePassForm extends React.Component {
  constructor(props) {
    super(props)
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
  }

  state = {
    password: '',
    repeatPassword: '',
    email: '',
    passwordChanged: false,
    passwordChangedError: false,
    passwordError: false,
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    });
  }

  handlePasswordSubmit = event => {
    event.preventDefault();
    const that = this;
    const state = this.state;
    axios
      .post('/auth/restore-password', {
        password: state.password,
        repeatPassword: state.repeatPassword,
        resetPasswordToken  : that.props.token,
      })
      .then(function(response) {
        if (response.status < 300) {
          that.setState({ passwordChanged: true })
          that.setState({ passwordChangedError: false })
        } else {
          that.setState({ passwordChangedError: true })
          that.setState({ passwordChanged: false })
        }
      })
      .catch(function() {
        that.setState({ passwordChangedError: true })
        that.setState({ passwordChanged: false })
      })
  }
  checkPassword() {
    this.setState({ passwordError: this.state.password !== this.state.repeatPassword})
  }

  render() {
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'block' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none', opacity: 1, width: '100%' }}
        >
          <h2 className="major">Actualizar password</h2>
          {!this.state.passwordChanged && (
            <div>
              <p>Introduce el nuevo password.</p>
              <form
                method="post"
                action="#"
                onSubmit={this.handlePasswordSubmit}
              >
                <div className="field">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onKeyUp={this.checkPassword}
                    value={this.state && this.state.password}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="field">
                  <label htmlFor="repeatPassword">Repetir password</label>
                  <input
                    type="password"
                    name="repeatPassword"
                    id="repeatPassword"
                    onKeyUp={this.checkPassword}
                    value={this.state && this.state.repeatPassword}
                    onChange={this.handleInputChange}
                  />
                </div>

                <ul className="actions">
                  <li>
                    <input
                      type="submit"
                      value="Actualizar"
                      className="special"
                    />
                  </li>
                </ul>
              </form>
            </div>
          )}
          {this.state.passwordChanged && (
            <div>
              <p>Passoword actualizado correctamente.</p>
            </div>
          )}
          {this.state.passwordChangedError && (
            <p>Se ha producido un error al actualizar el password.</p>
          )}
          {this.state.passwordError && <p>Los passwords deben coincidir.</p>}
        </article>
      </div>
    )
  }
}

UpdatePassForm.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
}

export default UpdatePassForm
